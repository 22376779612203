import clsx from 'clsx'
import { memo } from 'react'
import { InputHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import css from '../css/wellBeing.module.css'

const WellBeingTextInput = forwardRef<
  HTMLInputElement,
  WellBeingTextInputProps
>((props, ref) => {
  const { error, placeholder, ...rest } = props

  return (
    <input
      placeholder={placeholder}
      ref={ref}
      type="text"
      {...rest}
      className={clsx('w-full border-b p-1 border-gray-20', css.field)}
    />
  )
})

export interface WellBeingTextInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | boolean
}

export default memo(WellBeingTextInput)
