import Cookies from 'js-cookie'

import dayjs from '@/helpers/dayjs'
import getEndpoint from '@/helpers/getEndpoint'
import API from '@/services/API'
import { AuthPerson } from '@/ts/Global'

async function refreshToken(scope: AuthPerson = 'user') {
  const endpoint = getEndpoint('REFRESH_TOKEN')
  const body = { client_id, client_secret, scope }

  const response = await API.post<Success>(endpoint, { body })

  Cookies.remove(`${scope}_access_token`)
  Cookies.remove(`${scope}_refresh_token`)

  if (response.status === 'success') {
    const accessToken = response.content[`${scope}_access_token`]
    const refreshToken = response.content[`${scope}_refresh_token`]
    const expires = dayjs().add(2, 'hours').toDate()

    Cookies.set(`${scope}_access_token`, accessToken, { expires })
    Cookies.set(`${scope}_refresh_token`, refreshToken, { expires })

    return
  }
}

const client_id = process.env.NEXT_PUBLIC_CLIENT_ID
const client_secret = process.env.NEXT_PUBLIC_CLIENT_SECRET

type Success = Record<string, string>

export default refreshToken
