import { Variables } from 'graphql-request/dist/types'
import { useRouter } from 'next/router'
import { useQuery, UseQueryOptions } from 'react-query'

import { toast } from '@/molecules/Toast'
import Hasura from '@/services/Hasura/Hasura'

interface UseHasuraOptions<T> {
  config?: UseQueryOptions<T>
  variables?: Variables
}

function useHasura<T>(
  key: string | unknown[],
  query: string,
  options?: UseHasuraOptions<T>
) {
  const isEnabled = useRouter().isReady
  const request = async () =>
    await Hasura.request(query, options?.variables, { 'x-hasura-role': 'user' })

  const data = useQuery<T>([key, options?.variables], request, {
    enabled: isEnabled,
    keepPreviousData: true,
    onError: () => toast.error('server'),
    ...options?.config,
  })

  return data
}

export default useHasura
