import clsx from 'clsx'
import { memo } from 'react'
import { InputHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import css from '../css/index.module.css'

const FieldInput = forwardRef<HTMLInputElement, FieldInputProps>(
  (props, ref) => {
    const { error, placeholder, ...rest } = props

    return (
      <input
        placeholder={placeholder}
        ref={ref}
        type="text"
        {...rest}
        className={clsx(css.field, 'h-11 px-3 pt-0.5', props.className, {
          [css['field--error']]: error,
          'select-none': rest.readOnly,
        })}
      />
    )
  }
)

export interface FieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | boolean
  type?: 'text' | 'email' | 'number' | 'tel' | 'date' | 'password' | 'url'
}

export default memo(FieldInput)
