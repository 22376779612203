import clsx from 'clsx'
import { memo } from 'react'
import { TextareaHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import css from '../css/index.module.css'

const FieldTextArea = forwardRef<HTMLTextAreaElement, FieldTextAreaProps>(
  (props, ref) => {
    const { error, ...rest } = props
    return (
      <>
        <textarea
          className={clsx(css.field, 'p-4', props.className, {
            [css['field--error']]: error,
          })}
          ref={ref}
          {...rest}
        />
      </>
    )
  }
)

interface FieldTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string | boolean
}

export default memo(FieldTextArea)
