import NextLink from 'next/link'
import { LinkProps as NextLinkProps } from 'next/link'
import { ReactNode } from 'react'

function Link(props: LinkProps) {
  const { className, children, target, role, onClick, ...rest } = props
  return (
    <NextLink {...rest}>
      <a role={role} target={target} onClick={onClick} className={className}>
        {children}
      </a>
    </NextLink>
  )
}

interface LinkProps extends NextLinkProps {
  className?: string
  target?: string
  role?: string
  onClick?(): void
  children: ReactNode
}

export default Link
