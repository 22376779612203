import clsx from 'clsx'
import { memo } from 'react'
import { TextareaHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import css from '../css/wellBeing.module.css'

const WellBeingTextarea = forwardRef<
  HTMLTextAreaElement,
  WellBeingTextareaProps
>((props, ref) => {
  const { error, ...rest } = props
  return (
    <>
      <textarea
        className={clsx(
          'w-full border-gray-40 p-2 text-sm transition-colors duration-500 border rounded-lg resize-none',
          css.field
        )}
        ref={ref}
        {...rest}
      />
    </>
  )
})

interface WellBeingTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string | boolean
}

export default memo(WellBeingTextarea)
