import clsx from 'clsx'
import { TranslationQuery } from 'next-translate'

import Error from './includes/Error'
import Input from './includes/Input'
import Label from './includes/Label'
import TextArea from './includes/Textarea'
import WellBeingTextarea from './includes/WellBeingTextarea'
import WellBeingTextInput from './includes/WellBeingTextInput'

function Field(props: FieldProps) {
  const {
    id,
    label,
    children,
    className,
    errorMessage,
    translationParams,
  } = props

  return (
    <div className={clsx('relative', className)}>
      <Label id={id} value={label} />
      {children}
      <Error message={errorMessage} translationParams={translationParams} />
    </div>
  )
}

Field.Input = Input
Field.TextArea = TextArea
Field.WellBeingTextarea = WellBeingTextarea
Field.WellBeingTextInput = WellBeingTextInput

interface FieldProps {
  className?: string
  id?: string
  label?: string
  errorMessage?: string
  children: React.ReactNode
  translationParams?: TranslationQuery
}

export default Field
