const HOST_API = process.env.NEXT_PUBLIC_API_HOST as string
const HOST_PROVIDERS = process.env.NEXT_PUBLIC_PROVIDERS_HOST as string

const ENDPOINTS = {
  GRAPHQL: process.env.NEXT_PUBLIC_HASURA_GRAPHQL as string,
  CHECKOUT: HOST_API + 'marketplace/checkout',
  CHECKOUT_SIMILAR_PRODUCTS: HOST_API + 'marketplace/checkout/similar_products',
  CHECKOUT_FEATURED_PRODUCTS:
    HOST_API + 'marketplace/checkout/bestseller_products',
  CONFIRM_ACCOUNT: HOST_API + 'marketplace/users/confirm_account',
  CONTINUE_AS_GUEST: HOST_API + 'auth/sessions/user/guest',
  DELIVERY_ADDRESS: HOST_API + 'marketplace/profile/delivery_addresses',
  FORGOT_PASSWORD: HOST_API + 'marketplace/users/reset_password',
  GET_USER: HOST_API + 'marketplace/users/me',
  GET_PROMO_CODE: HOST_API + 'marketplace/checkout/check_promo_code/',
  LOGIN: HOST_API + 'auth/sessions/user',
  ORDERS: HOST_API + 'marketplace/orders/',
  PROVIDERS: {
    FACEBOOK: HOST_PROVIDERS + 'facebook',
    GOOGLE: HOST_PROVIDERS + 'google',
    APPLE: HOST_PROVIDERS + 'apple',
  },
  REFRESH_TOKEN: HOST_API + 'auth/refresh_tokens',
  REGISTER: HOST_API + 'marketplace/users',
  REGISTER_CREATOR: HOST_API + 'marketplace/creators',
  REVOKE_TOKEN: HOST_API + 'auth/revoke',
  SEND_CONFIRMATION_EMAIL:
    HOST_API + 'marketplace/users/send_confirmation_instructions',
  UPADTE_PASSWORD: HOST_API + 'marketplace/profile/update_password',
  UPDATE_PROFILE: HOST_API + 'marketplace/profile',
  VERIFY_EMAIL: HOST_API + 'marketplace/users/check_email_availability',
  VERIFY_NICKNAME:
    HOST_API + 'marketplace/creators/check_nickname_availability',
  VERIFY_SOCIAL_MEDIA: HOST_API + 'marketplace/creators/verify_social_medium',
}

export default ENDPOINTS
