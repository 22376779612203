import useTranslation from 'next-translate/useTranslation'

function FieldLabel({ id, value }: FieldLabelProps) {
  if (!value) return null

  const { t } = useTranslation('forms')

  return (
    <label
      className="block mb-2 pl-0.5 text-black/90 text-xs leading-none font-medium"
      htmlFor={id}
    >
      {t(value)}
    </label>
  )
}

interface FieldLabelProps {
  id?: string
  value?: string
}

export default FieldLabel
