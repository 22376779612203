import Cookies from 'js-cookie'
import { useQuery } from 'react-query'

import refreshToken from '@/api/requests/auth/refreshToken'
import getEndpoint from '@/helpers/getEndpoint'
import { AuthPerson, Endpoint } from '@/ts/Global'
import { User } from '@/ts/User'

function useAuth<T extends AuthPerson>(authPerson: T) {
  const key = `GET_` + authPerson.toUpperCase()
  const endpoint = getEndpoint(key as Endpoint)
  const hasAccess = Cookies.get(`${authPerson}_access_token`)

  const { data, isLoading = true, ...rest } = useQuery<Data<T>>(
    authPerson,
    async () => await request(endpoint),
    {
      enabled: !!hasAccess,
      retry: false,
      keepPreviousData: true,
      onSettled: async (user) => !user && (await refreshToken(authPerson)),
    }
  )

  if (!hasAccess) return { data: null, isLoading: false, ...rest }

  return { data, isLoading, ...rest }
}

async function request(endpoint: string) {
  return await fetch(endpoint, { credentials: 'include' }).then((response) => {
    if (response.status === 401) return null
    return response.json()
  })
}

type Data<T> = T extends 'user' ? User : null

export default useAuth
