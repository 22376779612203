import { TranslationQuery } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'

function FieldError(props: FieldErrorProps) {
  const { t } = useTranslation('alerts')
  const { message, translationParams } = props

  if (typeof message !== 'string') return null

  return (
    <span className="absolute right-0 text-xs leading-none top-full pt-1.5 pr-0.5 text-sbtred-600">
      {t(`error.${message}`, translationParams)}
    </span>
  )
}

interface FieldErrorProps {
  message?: string
  translationParams?: TranslationQuery
}

export default FieldError
